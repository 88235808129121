import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import App from './App';
import React from 'react';

export const Root = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  );
};

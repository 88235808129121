import { getRequest } from './apiHelper';
import { AccountTransactionsListResponse } from '../@types/AccountTransaction';
import { API_SERVER } from '../utils/envVars';
import { AccountTransactionType } from '../@types/enums';

export interface TransactionFilters {
  account_ids: number[];
  start_date?: string;
  end_date?: string;
  tags?: string[];
  transaction_type?: AccountTransactionType;
}

export const listTransactionsApi = async (
  filters: TransactionFilters,
  page: number,
  pageSize: number,
) => {
  const apiResponse = await getRequest<AccountTransactionsListResponse>(
    `${API_SERVER}/transactions`,
    {
      ...filters,
      page,
      items: pageSize,
    },
  );
  return apiResponse!;
};

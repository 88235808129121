import { Alert, Button, Collapse, List, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TransactionCard from './TransactionCard';
import './styles/transactionsList.scss';
import { Account } from '../../@types/Account';
import { useQuery } from 'react-query';
import {
  getCachedCurrentUser,
  queryKeyForAccountTransactionsList,
  refreshAccount,
} from '../helpers/storeHelper';
import {
  convertApiDateStringToDate,
  convertDateToApiDateString,
  showGenericOperationFailedMessage,
} from '../../utils/helpers';
import TransactionFormModal from './TransactionFormModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faX, faDownload } from '@fortawesome/free-solid-svg-icons';
import DatePicker from '../shared/DatePicker';
import {
  listTransactionsApi,
  TransactionFilters,
} from '../../api/transactions';
import { find, isEmpty } from 'lodash';
import CurrencyIcon from '../shared/Currency';
import { AccountTransactionType } from '../../@types/enums';
import { CSVLink } from 'react-csv';

interface TransactionsListProps {
  accounts: Account[];
  singleAccountMode?: boolean;
}

interface TransactionsListState {
  currentPage: number;
  filters: TransactionFilters;
  transactionFormModalVisible: boolean;
}

const DEFAULT_PAGE_SIZE = 10;
const TransactionsList = ({
  accounts,
  singleAccountMode,
}: TransactionsListProps) => {
  const { t } = useTranslation();
  const currentUser = getCachedCurrentUser();
  const singleAccount = singleAccountMode ? accounts[0] : null;
  const editMode =
    !!singleAccount && currentUser.id === singleAccount.account_group.user_id;

  const accountForId = (id: number) =>
    singleAccountMode
      ? singleAccount!
      : find(accounts, (account) => account.id === id);

  const initialState = (accounts: Account[]): TransactionsListState => {
    const accountIds = accounts.map((account) => account.id);
    return {
      currentPage: 1,
      transactionFormModalVisible: false,
      filters: { account_ids: accountIds },
    };
  };

  const [state, setState] = useState<TransactionsListState>(
    initialState(accounts),
  );

  // to force re-render when passed accounts changes
  useEffect(() => {
    setState(initialState(accounts));
  }, [JSON.stringify(accounts.map((account) => account.id))]);

  const queryKeyBase = singleAccountMode
    ? queryKeyForAccountTransactionsList(singleAccount!.id)
    : (['combinedTransactionsList'] as any);

  const {
    isLoading,
    error,
    data: transactionsResponse,
    isPreviousData,
  } = useQuery(
    queryKeyBase.concat([state.currentPage, JSON.stringify(state.filters)]),
    () =>
      listTransactionsApi(state.filters, state.currentPage, DEFAULT_PAGE_SIZE),
    {
      keepPreviousData: true,
      useErrorBoundary: false,
      onError: (e) => setCurrentPage(1),
    },
  );

  const isFiltersEmpty = () =>
    isEmpty(state.filters) ||
    (Object.keys(state.filters).length === 1 &&
      Object.keys(state.filters)[0] === 'account_ids');

  const onPageChange = (page: number) => setCurrentPage(page);

  const setCurrentPage = (page: number) => {
    setState((state) => ({ ...state, currentPage: page }));
  };

  const closeModals = (saved = false) => {
    setState((state) => ({
      ...state,
      currentPage: saved ? 1 : state.currentPage,
      transactionFormModalVisible: false,
    }));
  };

  /*
   * Add Transaction
   * shown in singleAccountMode only
   */

  const onSaveTransaction = async (mutationInfo: any) => {
    await refreshAccount(singleAccount!.id);
    closeModals(true);
  };

  const transactionFormModal = state.transactionFormModalVisible && (
    <TransactionFormModal
      onSave={onSaveTransaction}
      onCancel={closeModals}
      account={singleAccount!}
    />
  );

  const openTransactionFormModal = (event: any) => {
    setState((state) => ({
      ...state,
      transactionFormModalVisible: true,
    }));
  };

  const addTransactionsButton = editMode && (
    <div className="transactions-list-component__add-transactions">
      <Button
        className="transactions-list-component__add-transactions__button"
        onClick={openTransactionFormModal}
      >
        <FontAwesomeIcon icon={faPlus} color="green" />
        <span>{t('transaction.actions.add')}</span>
      </Button>
    </div>
  );

  /*
   * Filters
   */

  const transactionsDateFilter = (filterField: 'start_date' | 'end_date') => (
    <DatePicker
      allowClear={true}
      className="transaction-filters__input__date"
      value={
        state.filters[filterField]
          ? convertApiDateStringToDate(state.filters[filterField]!)
          : undefined
      }
      format="YYYY/MM/DD"
      name={filterField}
      placeholder={
        filterField === 'start_date'
          ? t('transaction.filters.startDate')
          : t('transaction.filters.endDate')
      }
      onChange={(selectedDate: Date, dateString: string | string[]) =>
        onDateFilterChange(filterField, selectedDate)
      }
    />
  );

  const onDateFilterChange = (
    filterField: 'start_date' | 'end_date',
    selectedDate: Date | null,
  ) => {
    setState((oldState) => {
      const filters = { ...oldState.filters };
      if (selectedDate) {
        filters[filterField] = convertDateToApiDateString(selectedDate);
      } else {
        delete filters[filterField];
      }
      return {
        ...oldState,
        filters,
      };
    });
  };

  const onTagsFilterChange = (tags: string[]) => {
    setState((oldState) => {
      const filters = { ...oldState.filters };
      filters.tags = tags || [];
      return {
        ...oldState,
        filters,
      };
    });
  };

  const onTransactionTypeFilterChange = (
    transaction_type: AccountTransactionType,
  ) => {
    setState((oldState) => {
      const filters = { ...oldState.filters };
      if (transaction_type) {
        filters.transaction_type = transaction_type;
      } else {
        delete filters.transaction_type;
      }
      return {
        ...oldState,
        filters,
      };
    });
  };

  const clearFilters = async () => setState(initialState(accounts));

  const transactionsFilters = (
    <div className="transactions-filters">
      <Space className="transactions-filters__space">
        <div className="transaction-filters__input">
          {transactionsDateFilter('start_date')}
        </div>
        <div className="transaction-filters__input">
          {transactionsDateFilter('end_date')}
        </div>
        <div className="transaction-filters__input">
          <Select
            style={{ width: '130px' }}
            placeholder={t('transaction.filters.transactionType')}
            onChange={onTransactionTypeFilterChange}
            value={state.filters.transaction_type || undefined}
            options={Object.values(AccountTransactionType).map(
              (transaction_type) => ({
                label: t(`transaction.transactionTypes.${transaction_type}`),
                value: transaction_type,
              }),
            )}
          />
        </div>
        <div className="transaction-filters__input">
          <Select
            mode="multiple"
            style={{ width: '164px' }}
            placeholder={t('transaction.filters.tags')}
            onChange={onTagsFilterChange}
            value={state.filters.tags || []}
            options={(transactionsResponse?.data?.all_tags || []).map(
              (tag) => ({
                label: tag,
                value: tag,
              }),
            )}
          />
        </div>
        <div className="transaction-filters__input">
          <Button
            type="primary"
            onClick={clearFilters}
            disabled={isLoading || isFiltersEmpty()}
          >
            <FontAwesomeIcon
              icon={faX}
              color="white"
              style={{ margin: '0px 5px' }}
            />
          </Button>
        </div>
      </Space>
    </div>
  );

  const transactionsFiltersSection = (
    <Collapse
      defaultActiveKey={[1]}
      className="account-groups-collapsible"
      key="0-1"
      items={[
        {
          key: `collapse_panel_transactions_filter`,
          label: t('transaction.words.filters'),
          children: transactionsFilters,
        },
      ]}
    />
  );

  const exportToCSVButton = () => {
    const csvRows = [];
    // export functionality should be only available to singleAccountMode
    const account = accounts[0];
    const accountCurrencyString = t(`account.currency.${account.currency}`);
    csvRows.push([
      account.name,
      t('stats.totalBalance'),
      accountCurrencyString,
      Number(account.balance || 0).toLocaleString(),
    ]);

    // a couple of empty rows
    csvRows.push(['']);
    csvRows.push(['']);

    // transaction list headers
    csvRows.push([
      t('transaction.words.date'),
      t('transaction.words.transactionType'),
      t('transaction.words.amount'),
      t('transaction.words.description'),
    ]);
    // transaction list data
    (transactionsResponse?.data?.account_transactions || []).forEach(
      (transaction) => {
        csvRows.push([
          transaction.date,
          t(`transaction.transactionTypes.${transaction.transaction_type}`),
          Number(transaction.summable_amount || 0).toLocaleString(),
          transaction.description || '',
        ]);
      },
    );

    // transaction list total sum
    csvRows.push([
      t('stats.totalBalance'),
      accountCurrencyString,
      Number(
        transactionsResponse?.data?.filtered_balance || 0,
      ).toLocaleString(),
    ]);

    const filename = `${account.name}_${account.currency}_${new Date().toLocaleDateString()}.csv`;
    return (
      <CSVLink data={csvRows} filename={filename} className="btn btn-primary">
        <FontAwesomeIcon icon={faDownload} color="green" />
      </CSVLink>
    );
  };

  const transactionsListInfoSection = () => (
    <div className="transactions-list__info-section">
      <div className="transactions-list__info-section__action-buttons">
        {exportToCSVButton()}
      </div>
      {!isFiltersEmpty() && (
        <div
          className="transactions-list__info-section__filtered_summary"
          style={filteredBalanceStyle()}
        >
          <span>{t('transaction.list.filteredBalance')}: </span>
          <div className="transactions-list__info-section__filtered_summary__amount">
            <span>
              <CurrencyIcon
                currencyType={
                  accountForId(
                    transactionsResponse!.data!.account_transactions![0]
                      .account_id,
                  )!.currency
                }
              />
            </span>
            <span className="transactions-list__info-section__filtered_summary__amount__value">
              {Number(
                transactionsResponse?.data?.filtered_balance || 0,
              ).toLocaleString()}
            </span>
          </div>
        </div>
      )}
    </div>
  );

  /*
   * ui
   */

  if (error) showGenericOperationFailedMessage(error, t);

  const filteredBalanceStyle = () =>
    (transactionsResponse?.data?.filtered_balance || 0) >= 0
      ? { color: 'green' }
      : { color: 'red' };

  return (
    <div className="transactions-list-component">
      {addTransactionsButton}
      {transactionsFiltersSection}
      {!!error && (
        <Alert message={t('generic.errors.dataIncomplete')} type={'error'} />
      )}
      {singleAccountMode &&
        !isEmpty(transactionsResponse?.data?.account_transactions) &&
        transactionsListInfoSection()}
      <div className="transactions-list">
        <List
          key={'transaction_list_paginated'}
          itemLayout="vertical"
          loading={isLoading}
          pagination={{
            current: state.currentPage,
            onChange: onPageChange,
            pageSize: DEFAULT_PAGE_SIZE,
            defaultPageSize: DEFAULT_PAGE_SIZE,
            total: transactionsResponse?.meta?.count || 0,
            disabled: isLoading || isPreviousData,
          }}
          dataSource={transactionsResponse?.data?.account_transactions || []}
          renderItem={(transaction) =>
            // this accountForId check is important as it covers the case when the account list is updated but the
            // transactions response list is not fully updated yet
            accountForId(transaction.account_id) && (
              <List.Item
                key={transaction.id}
                className="transactions-list__item"
              >
                <TransactionCard
                  key={`transaction_card_in_list_for_${transaction.id}`}
                  account={accountForId(transaction.account_id)!}
                  transaction={transaction}
                  editMode={editMode}
                  singleAccountMode={singleAccountMode}
                />
              </List.Item>
            )
          }
        />
      </div>
      {transactionFormModal}
    </div>
  );
};

export default TransactionsList;
